
import { servicePath } from 'constants/defaultValues';
import Cookies from 'js-cookie';
import { formatListMessages, parseJson } from './HtmlHelper';

const buildJsonOptions = (method, params) => {

  return {
    method: method, // 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'include', //'same-origin', // include, *same-origin, omit
    crossDomain: true,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-CSRF-TOKEN': Cookies.get('csrf_access_token')
    },
    // redirect: 'follow', // manual, *follow, error
    // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: params ? JSON.stringify(params) : null, // body data type must match "Content-Type" header
  }
}

const mapUrl = (endpoint) => {
  // const currentUser = getCurrentUser();
  // if (currentUser)
  //   endpoint = endpoint.replaceAll("$tenant", currentUser?.tenant?.hostName);
  if (endpoint.startsWith("http")) return endpoint
  const url = servicePath + (servicePath.endsWith("/") ? "" : "/") + endpoint;
  console.log(url)
  return url;
}

const fetchAsync = async (method, endpoint, params) => {
  return await fetcher(endpoint, buildJsonOptions(method, params));
}

const fetcherSkipRedir = async (endpoint, options) => {
  try {
    const response = await fetch(mapUrl(endpoint), options);
    if (response.ok) {
      let data = {};
      try {
        data = await response.json(); // this would raise error i.e. Delete method
      } catch { }
      return { data: data, ok: response.ok, code: response.status }
    }
    return { data: response.statusText, ok: false, code: response.status }

  } catch (ex) {
    console.log(ex)
    return errorCatcher(ex);
  }
}

const fetcher = async (endpoint, options) => {
  try {
    const response = await fetch(mapUrl(endpoint), options);
    if (response.ok) {
      let data = {};
      try {
        data = await response.json(); // this would raise error i.e. Delete method
      } catch { }
      return { data: data, ok: response.ok, code: response.status }
    }
    const msg = await checkResponseAsync(response, () => {
      return fetcher(endpoint, options);
    });
    return { data: msg == "" ? response.statusText : msg, ok: false, code: response.status }

  } catch (ex) {
    console.log(ex)
    return errorCatcher(ex);
  }
}

const errorCatcher = (ex) => {
  console.log(ex)
  let errMsg = ex.message;
  if (errMsg == "Failed to fetch")
    errMsg = `Could not connect to API server. Please try again later.`
  return {
    data: errMsg, ok: false, code: 500
  }
}

const getBlob = async (endpoint) => {
  const options = buildJsonOptions("GET", null);
  try {
    const response = await fetch(mapUrl(endpoint), options);
    if (response.ok) {
      const data = await response.blob();
      return { data: data, ok: response.ok, code: response.status }
    }
    const msg = await checkResponseAsync(response, () => {
      return getBlob(endpoint);
    });

    return { data: msg, ok: false, code: response.status }

  } catch (ex) {
    return errorCatcher(ex)
  }
}

const get = async (endpoint) => {
  return fetchAsync('GET', endpoint)
}

const tryGet = async (endpoint) => {
  return await fetcherSkipRedir(endpoint, buildJsonOptions("GET"));
}

const fetchx = (endpoint) => {
  const options = buildJsonOptions("GET", null);
  return fetch(mapUrl(endpoint), options);
}

const post = async (endpoint, params) => {
  return fetchAsync('POST', endpoint, params)
}

const put = async (endpoint, params) => {
  return fetchAsync('PUT', endpoint, params)
}


const postOrPut = async (endpoint, editingId, params) => {
  if (editingId) {
    const url = endpoint + (endpoint.endsWith("/") ? editingId : "/" + editingId)
    return put(url, params)
  }
  return post(endpoint, params)
}

const deletex = async (endpoint) => {
  return fetchAsync('DELETE', endpoint)
}


const postFormData = async (endpoint, formdata) => {
  var options = {
    method: 'POST',
    body: formdata, crossDomain: true,
    redirect: 'follow', mode: 'cors', credentials: 'include',
    headers: {
      //'Content-Type': 'multipart/form-data',
      'X-CSRF-TOKEN': Cookies.get('csrf_access_token')
    },
  };
  return await fetcher(endpoint, options);

}

const checkResponseAsync = async (response, onAuthRefreshed) => {
  let msg = await response.text();

  if (msg.includes("Signature has expired") || msg.includes("Session has expired")) {
    const refreshRes = await post("auth/refresh", {});
    if (refreshRes.ok) {
      console.log("Token refreshed.")
      if (onAuthRefreshed) {
        onAuthRefreshed();
        return ""
      }
    }
    // else {
    //   localStorage.clear();
    //   window.location.href = "/account/login";
    // }
  }

  if (response.status === 401) {
    localStorage.clear();
    window.location.href = "/account/login";
  }
  try {
    const err = parseJson(msg)
    if (err) msg = stringifyError(err)
  } finally {
    return msg;
  }

}

const stringifyError = (err) => {
  let msg = "";
  if (err?.errors) { //&& errJson.type?.includes("https://tools.ietf.org/html/")) {
    msg = formatListMessages(`${err.status}: ${err.title}`, err.errors)
  } else if (err?.message)
    msg = err.message;
  else
    msg = JSON.stringify(err);
  return msg;
}

const apix = {
  get,
  post,
  put,
  postOrPut,
  deletex,
  postFormData,
  getBlob,
  fetchx,
  tryGet,
}
export default apix;