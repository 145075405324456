import {
  defaultDirection,
  defaultLocale,
  defaultColor,
  localeOptions,
  themeColorStorageKey,
  themeRadiusStorageKey,
  currentUser,
} from 'constants/defaultValues';
import { users } from 'lang/locales/en_US';
import { useContext } from 'react';
import apix from './Apix';
import { CurrentUserContext } from './Context';


// export const compare = (a, b, prop) => {
//   if (a[prop] < b[prop])
//       return -1;

//   if (a[prop] > b[prop])
//       return 1;

//   return 0;
// }

export const compareProp = (propName) =>
  (a, b) => a[propName] == b[propName] ? 0 : a[propName] < b[propName] ? -1 : 1

export const compareName = (a, b) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
}

export const mapOrder = (array, order, key) => {
  // eslint-disable-next-line func-names
  array.sort(function (a, b) {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}.${mm}.${yyyy}`;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const getDirection = () => {
  let direction = defaultDirection;

  try {
    if (localStorage.getItem('direction')) {
      const localValue = localStorage.getItem('direction');
      if (localValue === 'rtl' || localValue === 'ltr') {
        direction = localValue;
      }
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getDirection -> error', error);
    direction = defaultDirection;
  }
  return {
    direction,
    isRtl: direction === 'rtl',
  };
};
export const setDirection = (localValue) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  try {
    localStorage.setItem('direction', direction);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setDirection -> error', error);
  }
};

export const getCurrentColor = () => {
  let currentColor = defaultColor;
  try {
    if (localStorage.getItem(themeColorStorageKey)) {
      currentColor = localStorage.getItem(themeColorStorageKey);
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getCurrentColor -> error', error);
    currentColor = defaultColor;
  }
  return currentColor;
};

export const getReactGridTheme = () => getCurrentColor().startsWith("dark.") ? "default-dark" : "default-light";

export const setCurrentColor = (color) => {
  try {
    localStorage.setItem(themeColorStorageKey, color);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentColor -> error', error);
  }
};

export const getCurrentRadius = () => {
  let currentRadius = 'flat';// 'rounded';
  try {
    if (localStorage.getItem(themeRadiusStorageKey)) {
      currentRadius = localStorage.getItem(themeRadiusStorageKey);
    }
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentRadius -> error',
      error
    );
  }
  return currentRadius;
};
export const setCurrentRadius = (radius) => {
  try {
    localStorage.setItem(themeRadiusStorageKey, radius);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentRadius -> error',
      error
    );
  }
};

export const getCurrentLanguage = () => {
  let language = defaultLocale;
  try {
    language =
      localStorage.getItem('currentLanguage') &&
        localeOptions.filter(
          (x) => x.id === localStorage.getItem('currentLanguage')
        ).length > 0
        ? localStorage.getItem('currentLanguage')
        : defaultLocale;
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentLanguage -> error',
      error
    );
    language = defaultLocale;
  }
  return language;
};
export const setCurrentLanguage = (locale) => {
  try {
    localStorage.setItem('currentLanguage', locale);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentLanguage -> error',
      error
    );
  }
};


export const getCurrentUser = () => {

  let user = null;
  try {

    const userItem = localStorage.getItem('current_user');
    let userCtx = CurrentUserContext;

    if (userItem) user = JSON.parse(userItem);

    if (userCtx && Object.keys(userCtx._currentValue).length > 0) {
      user = { ...user, ...userCtx._currentValue }
    }

  } catch (error) {
    // console.log('>>>>: src/helpers/Utils.js  : getCurrentUser -> error', error);
  }
  return user;
};


export const setCurrentUser = (user) => {
  try {
    if (user) {
      localStorage.setItem('current_user', JSON.stringify(user));
    } else {
      localStorage.removeItem('current_user');
      console.log("Logout")
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentUser -> error', error);
  }
};

// export const setCurrentUserSettings = (settings) => {
//   try {
//     localStorage.setItem('settings', JSON.stringify(settings));
//   } catch (error) {
//     console.log('>>>>: src/helpers/Utils.js : setCurrentUserSettings -> error', error);
//   }
// };

export const setCurrentUserScopes = (scopes) => {
  try {
    localStorage.setItem('scopes', JSON.stringify(scopes));
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentUserScopes -> error', error);
  }
};

export const parsePydantic = (msg) => {
  try {
    let msgs = []
    let json = {};

    try {
      json = JSON.parse(msg.toString())
    }
    catch {
      json = JSON.parse(JSON.stringify(msg))
    }

    json.detail.forEach(m => {
      msgs.push(m.loc + ": " + m.msg)
    });
    return msgs.join(". ").replace("body:", "")
  } catch {
    return msg
  }
};

export const tryParseJSON = (json) => {
  try {
    return JSON.parse(json.toString())
  } catch {
    return json.toString()
  }
};



