import { getCurrentLanguage } from 'helpers/Utils';
import { CHANGE_LOCALE, GET_SYSTEM_SETTINGS } from '../contants';

const INIT_STATE = {
  locale: getCurrentLanguage(),
  systemSettings: {}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_LOCALE:
      return { ...state, locale: action.payload };
    case GET_SYSTEM_SETTINGS:
      return { ...state, locale: action.payload };

    default:
      return { ...state };
  }
};
