import axios from 'axios';
import apix from './Apix';
import alertify from 'alertifyjs';
import IntlMessages from './IntlMessages';
import { useIntl, FormattedMessage } from 'react-intl';
import AppLocale from 'lang';
import { decode } from 'urlsafe-base64';

const expirationMsgs = ["Signature has expired", "Session has expired"];
const axiosApiInstance = axios.create();

const logoutUser = () => {
  localStorage.clear();
  window.location.href = "/account/logout";
}

const refreshToken = async (originalRequest) => {
  const refreshRes = await apix.post("auth/refresh", {});
  if (refreshRes.ok) {
    return axiosApiInstance(originalRequest);
  }
  // else {
  //   logoutUser();
  // }

}

function decodeHtml(html) {
  html = html.replace("\n", "<br>").replace("\r", "<br><br>")
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return decodeURI(txt.value);
}

export const displayErrorDialog = (error) => {
  console.log(error)
  const Errors = `<div className="text-danger">${decodeHtml(error.message)}</div>`;
  alertify.alert(Errors).set({ title: `${error.response.statusText} (${error.response.status})`, label: "OK" });
}


export const showSuccessMessage = (method, msg) => {

  method = method.toLowerCase();

  const currentLang = localStorage.getItem("currentLanguage") || 'en';

  const messages = AppLocale[currentLang]["messages"];

  const getMessage = (id) => {
    if (messages[id]) {
      return messages[id];
    }
    return id;
  }

  if (msg && ["post", "put"].indexOf(method) > -1) {
    return alertify.success(getMessage(msg));
  }


  if (method == 'post') {
    alertify.success(getMessage('crud_msg.succes_save'));
  }

  if (method == 'put') {
    alertify.success(getMessage('crud_msg.succes_save'));
  }
}


const axiosInterceptor = () => {

  axios.interceptors.response.use(function (response) {

    const showMsg = axios.showSuccessMessage ?? true;
    const msg = axios.successMessage ?? null;

    if (showMsg) {
      showSuccessMessage(response.config.method, msg);
    }


    return response
  }, async function (error) {
    const originalRequest = error.config;
    const statusText = error.response.statusText;
    let responseMsg;

    try {
      responseMsg = error.response.data.detail[0].msg;
    } catch {
      responseMsg = null;
    }

    if (expirationMsgs.indexOf(statusText) > -1 || expirationMsgs.indexOf(responseMsg) > -1) {
      return refreshToken(originalRequest);
    }

    if (error?.response?.status == 401) {
      logoutUser()
    }


    if (axios.displayErrorDialog) {
      displayErrorDialog(error);
    }

    return Promise.reject(error)
  })

  axios.interceptors.request.use(function (config) {
    if (config.data != undefined) {
      Object.keys(config.data).map((key) => {
        if (typeof (config.data[key]) === 'string') {
          if (config.data[key] === "") {
            config.data[key] = null;
          }
          else if (config.data[key] === "<p></p>\n" || config.data[key] === "<p></p>") {
            config.data[key] = null;
          }
        }
        else if (config.data[key] instanceof Array) {
          if (config.data[key].length > 0) {
            config.data[key].map(d => {
              Object.keys(d).map((k) => {
                if (typeof (d[k]) === 'string') {
                  if (d[k] === "") {
                    d[k] = null;
                  }
                  else if (d[k] === "<p></p>\n" || d[k] === "<p></p>") {
                    d[k] = null;
                  }
                }
                else if (d[k] instanceof Array) {
                  if (d[k].length > 0) {
                    d[k].map(d => {
                      if (typeof (d) === 'string') {
                        if (d === "") {
                          d = null;
                        }
                        else if (d === "<p></p>\n" || d === "<p></p>") {
                          d = null;
                        }
                      }
                    })
                  }
                }
              })
            })
          }
        }
      })
    }
    return config;
  });


}


axiosInterceptor();

